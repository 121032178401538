import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Progress } from "reactstrap";
import axios from "axios";

import FileUploader from "../../components/file-input/file-input";
import dialog from "../../components/dialog/dialog.helper";
import getFolderNodeRef from "../../helpers/helper";
// styles
import "../uploader-form/uploader-form.container.css";

const Uploaderform = () => {
  const [docTypes, setDocTypes] = useState([]);
  const [uploadNodeId, setUploadNodeId] = useState("");
  const [progress, setProgress] = useState({});
  const [files, setfiles] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [values, setValues] = useState({
    name: "",
    email: "",
    id: "",
  });

  const getNodeId = () => {
    const { folderNodeId } = getFolderNodeRef();
    setUploadNodeId(folderNodeId);
  };
  const getDocTypesFromPageURL = () => {
    const urlString = window.location.search.substring(
      window.location.search.indexOf("?") + 1,
      window.location.search.indexOf("&")
    );
    const decodedURI = decodeURIComponent(urlString);
    let docTypes = decodedURI.split(",");
    setDocTypes(docTypes);
    return docTypes;
  };
  useEffect(() => {
    getDocTypesFromPageURL();
    getNodeId();
  }, []);

  const validate = (value) => {
    const emailPattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!emailPattern.test(value)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const uploadFiles = async (event) => {
    event.preventDefault();
    if (values.name && values.email && !emailError) {
      dialog.success(`file(s) upload is in progress...`);
      for (const file of files) {
        const nodeType = file.nodeType;
        const fileData = file.Files;
        for (const item of fileData) {
          const formData = new FormData();
          formData.append("relativePath", values.id);
          formData.append("cm:title", "File is uploaded by " + values.name);
          formData.append(
            "cm:description",
            "This file is uploaded by an employee whose name is " +
              values.name +
              " and email Id is " +
              values.email
          );
          formData.append("nodeType", nodeType);
          formData.append("filedata", item);

          const config = {
            method: "post",
            url: `${process.env.REACT_APP_UPLOAD_API_URL}/${uploadNodeId}/children`,
            auth: {
              username: process.env.REACT_APP_USER_ID,
              password: process.env.REACT_APP_PASSWORD,
            },
            data: formData,
            onUploadProgress: (ProgressEvent) => {
              const { loaded, total } = ProgressEvent;
              let percent = Math.floor((loaded * 100) / total);
              if (percent < 100) {
                setProgress({ [item.name]: percent });
              }
            },
          };
          try {
            // eslint-disable-next-line
            const response = await axios(config);
            setProgress({ [item.name]: 100 });

            dialog.success(`${item.name} is uploaded successfully`);
          } catch (error) {
            setProgress({ [item.name]: 0 });
            dialog.error(`${item.name} failed to upload`);
          }
        }
      }
      setTimeout(() => {
        setfiles([]);
        setProgress({});
        setValues({
          name: "",
          email: "",
          id: "",
        });
        setSubmitted(false);
      }, 1000);
    } else {
      setSubmitted(true);
    }
  };

  const storeFileProperty = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setValues({ ...values, [name]: value });
    if (name === "email") {
      validate(value);
    }
  };
  const handleSelect = (type, Files) => {
    if (!Files) return;
    setfiles([
      ...files,
      {
        nodeType: type,
        Files,
      },
    ]);
  };

  function deleteFile(e) {
    const s = Array.from(files).map((file) =>
      Array.from(file.Files).filter((item, index) => item !== e)
    );
    const updatedarr = s.map((data) => {
      return { Files: data };
    });
    setfiles(updatedarr);
  }

  return (
    <div className="container">
      <div className="title">Upload Files to DOMS</div>
      <form>
        <div className="user-details">
          <div className="input-box">
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Name"
              value={values.name}
              onChange={storeFileProperty}
              required
            />
            {submitted && !values.name && (
              <div id="first-name-error" style={{ color: "red" }}>
                Field 'name' is required
              </div>
            )}

            <input
              type="text"
              id="email"
              name="email"
              placeholder="Email Address"
              value={values.email}
              onChange={storeFileProperty}
              required
            />
            {submitted && !values.email && (
              <div id="first-name-error" style={{ color: "red" }}>
                Field 'email' is required
              </div>
            )}
            {emailError ? (
              <div id="first-name-error" style={{ color: "red" }}>
                invalid email
              </div>
            ) : null}

            <input
              type="text"
              id="id"
              name="id"
              placeholder="Employee Id (if any)"
              value={values.id}
              onChange={storeFileProperty}
              required
            />
          </div>
        </div>
        <div className="select-files">
          <div className="choose-files">
            {Object.entries(docTypes).map(([key, value]) => (
              <FileUploader
                key={key}
                docTypeKey={value.substring(value, value.indexOf("="))}
                docType={value
                  .substring(value.indexOf("=") + 1)
                  .replace(/%20/g, " ")}
                handleSelect={handleSelect}
              />
            ))}
          </div>
        </div>
        <div>
          {Array.from(files).map((file, index) => {
            const fileData = file.Files;
            return Array.from(fileData).map((item, index) => (
              <div className="files-to-upload" key={index}>
                <h5 className="file-name">{item.name}</h5>
                {progress[item.name] > 0 && (
                  <div
                    className="file-progress-bar"
                    style={{
                      display: "block",
                      width: 250,
                      animation: "mymove 5s infinite",
                      animationTimingFunction: "linear",
                    }}
                  >
                    <Progress
                      value={progress[item.name]}
                      color="success"
                      max="100"
                    >
                      {Math.round(progress[item.name])}
                    </Progress>
                  </div>
                )}

                <FontAwesomeIcon
                  icon={faTimesCircle}
                  className="cancel-file-upload circle-icon"
                  id={index}
                  onClick={() => {
                    deleteFile(item);
                  }}
                />
              </div>
            ));
          })}
        </div>
        <div className="button">
          <button className="upload-files" onClick={uploadFiles}>
            Upload
          </button>
        </div>
      </form>
    </div>
  );
};
export default Uploaderform;
