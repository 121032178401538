import React, { useRef } from "react";
import "./file-input.css";

const FileUploader = ({ id, docTypeKey,docType, handleSelect = () => {} }) => {
  const fileInput = useRef(null);

  const handleChange = (e) => {
    e.preventDefault();
    handleSelect(e.target.name, e.target.files);
  };
  const handleClick = (event) => {
    event.preventDefault();
    fileInput.current.click();
  };

  return (
    <div className="file-uploader">
      <label className="doc_type--label">{docType}</label>

      <input
        id={id}
        name={docTypeKey}
        type="file"
        multiple
        onChange={handleChange}
        ref={fileInput}
        style={{ display: "none" }}
      />
      <button onClick={handleClick} className="choose-file-Btn">
        Choose file(s)
      </button>
    </div>
  );
};
export default FileUploader;
