import "dotenv/config";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Uploaderform from "./containers/uploader-form/uploader-form.container";
import PageNotFound from "./containers/error-page/error-page";
import getFolderNodeRef from "./helpers/helper";

import { Spinner } from "react-bootstrap";
// styles
import "./App.css";

const App = () => {
  // const [, setTicket] = useState(null);
  const [expire, setExpire] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadNodeRef, setUploadNodeRef] = useState("");

  // useEffect(() => {
  //   const config = {
  //     method: "post",
  //     url: process.env.REACT_APP_CREATE_TICKET_API_URL,
  //     auth: {
  //       username: process.env.REACT_APP_USER_ID,
  //       password: process.env.REACT_APP_PASSWORD,
  //     },
  //     data: {
  //       userId: process.env.REACT_APP_USER_ID,
  //       password: process.env.REACT_APP_PASSWORD,
  //     },
  //   };
  //   axios(config)
  //     .then(function (response) {
  //       setTicket(response.data.entry.id);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }, []);
  const getNodeRef = () => {
    const { decodedNodeRef } = getFolderNodeRef();
    setUploadNodeRef(decodedNodeRef);
  };

  const getExpiryDate = async () => {
    try {
      const response = await axios.request({
        method: "get",
        url: `${process.env.REACT_APP_GET_EXPIRY_DATE_API_URL}?${uploadNodeRef}`,
        auth: {
          username: process.env.REACT_APP_USER_ID,
          password: process.env.REACT_APP_PASSWORD,
        },
      });
      setLoading(true);
      return response.data.expiryDate;
    } catch (error) {
      throw error;
    }
  };
  useEffect(() => {
    getNodeRef();
    const getExpiry = async () => {
      const urlExpireDate = await getExpiryDate();
      checkUrlExpiry(urlExpireDate);
    };
    getExpiry();
  });

  const checkUrlExpiry = (urlExpireDate) => {
    if (new Date().getTime() >= new Date(urlExpireDate).getTime()) {
      setExpire(true);
    } else {
      setExpire(false);
    }
  };

  return (
    <div className="App">
      {loading ? (
        !expire ? (
          <Uploaderform />
        ) : (
          <PageNotFound />
        )
      ) : (
        <div className="loading-indicator">
          <Spinner animation="border" variant="info" />
          Loading...
        </div>
      )}
      {/* <div>
          <Spinner animation="grow" variant="info" />
          Loading...
        </div>
      }
      {expire ? <Uploaderform /> : <PageNotFound />} */}
    </div>
  );
};
export default App;
