import React from "react";
import "./error-page.css";
const PageNotFound = () => {
  return (
    <div className="error--page">
      {/*  Middle Content */}
      <div className="error-page-content">
        <h1>404 - Page Not Found</h1>
        <h3 className="my-30">Oops! your upload file URL is expired.</h3>
      </div>

      {/* End Container */}
    </div>
  );
};
export default PageNotFound;
